@media(prefers-reduced-motion: no-preference) {
    html {
        scroll-behavior: smooth
    }
}
:where(input, button, textarea, select) {
    font: inherit;
}
.footer {
    background-color: #6eb92b;
    color: #fff;
    font-family: YakuHanJP, "Noto Sans JP", sans-serif;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.5;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 17px;
    position: relative
}
.footer__links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px
}
.footer__link {
    line-height: 1;
    color: #fff;
    font-size: .75rem;
    letter-spacing: 0;
    font-weight: 700;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/assets/renewal/smp/img/icon/arrow_white_right.svg);
    background-repeat: no-repeat;
    background-size: 6px 10px;
    background-position: right 10px center;
    background-color: rgba(255, 255, 255, .2);
    letter-spacing: 0;
    height: 42px;
    border-radius: 5px;
    transition: background-color .3s
}
.footer__link:hover, .footer__link:active {
    background-color: rgba(255, 255, 255, .1)
}
.footer__link.footer__link--tab {
    -moz-column-gap: 10px;
    column-gap: 10px
}
.footer__link.footer__link--tab::after {
    content: url(/assets/renewal/smp/img/icon/tab-link_white.svg);
    display: block;
    width: 12px;
    height: 12px
}
.footer__txt {
    font-size: .6875rem;
    margin-top: 20px;
    line-height: 1.45
}
.footer__copyright {
    background-color: #6eb92b;
    font-size: .75rem;
    margin-top: 36px
}
.footer__totop {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-top-left-radius: 3px;
    display: grid;
    place-items: center;
    transition: background-color .3s
}
.footer__totop:hover, .footer__totop:active {
    background-color: #e8f2df
}
.footer__totop .icon {
    width: 19px
}

.top-prefecture__btm {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: justify;
    word-break: break-all;
    -webkit-hyphens: auto;
    hyphens: auto;
    background-color: #f5f5f5;
    line-height: 1.7
}

.top-prefecture__btm--white {
    font-size: 12px;
    line-height: 1.6666;
    background-color: #fff
}

.footer__container {
    display:block;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto
    
}